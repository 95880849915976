import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import SubHeader from "../components/SubHeader"
import '../styles/global.css'

const Videography = ({ data }) => {
  const videos = data.video.nodes

  return (
    <Layout>
      <Helmet
          htmlAttributes={{lang:"en" }}
          title="PlanetFx - Our Products"
          meta={[{name:"viewport", content:"width=device-width, initial-scale=1.0"}, {name:"description", content:"Planet Fx adalah studio kreatif pembuatan Company Profile, Iklan, Explainer, ILM, Teaser, Bumper berupa Animasi Flat Motion Graphic dan juga pembuatan Media Interaktif dan Design"}]}>
      </Helmet>      
      <SubHeader title="PRODUK"/>
      <main>
        <section id="content" className="layout">
          <h2>Videography</h2>
          <hr/>
          <article className="product-contents">
            {videos.slice(0, 42).map(video => (
               <a className="product-content" key={video.contentful_id} href={video.link} target="_blank" rel="noreferrer">
                  <GatsbyImage image={video.thumb.gatsbyImageData} alt={video.title} placeholder="blurred"/>
                  <p>{video.title}</p>
                </a>
            ))}
          </article>
        </section>
      </main>
    </Layout>
  )
}

export default Videography

export const query = graphql`
query ProductVideo {
  video: allContentfulProductVideo(
    sort: {fields: createdAt, order: DESC}
  ) {
    nodes {
      contentful_id
      link
      title
      thumb {
        gatsbyImageData(width: 300)
      }
    }
  }
}`